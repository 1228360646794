<template>
  <div>
    <b-card>
      <h1 class="text-center">
        {{
          $t("lbl_welcome_to_hodo_e_commerce", {
            name:
              (supplierInfo && supplierInfo.name) ||
              (processorsInfo && processorsInfo.name),
          })
        }}
      </h1>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {};
  },
  async mounted() {},
  computed: {
    ...mapState({
      supplierInfo: (state) => state.authenticate.userLogin?.supplierInfo || {},
      processorsInfo: (state) =>
        state.authenticate.userLogin?.processorsInfo || {},
    }),
  },
  methods: {},
};
</script>

<style>
</style>
